<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Privacy Setting</h4>
          </template>
          <template v-slot:body>
            <div class="acc-privacy">
              <div class="data-privacy">
                <h4 class="mb-2">Account Privacy</h4>
                <b-form-checkbox v-model="isPrivate" inline>Private Account</b-form-checkbox>
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
              </div>
              <hr>
              <div class="data-privacy">
                <h4 class="mb-2">Activity Status</h4>
                <b-form-checkbox v-model="isActivityStatus" inline>Show Activity Status</b-form-checkbox>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
              </div>
              <hr>
              <div class="data-privacy">
                <h4 class="mb-2">Story Sharing</h4>
                <b-form-checkbox v-model="isAllowSharing" inline>Allow Sharing</b-form-checkbox>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
              </div>
              <hr>
              <div class="data-privacy">
                <h4 class="mb-2">Photo Of You </h4>
                <div class="custom-control custom-radio">
                  <input type="radio" id="automatically" name="customRadio0" class="custom-control-input" checked="" >
                  <label class="custom-control-label" for="automatically"> Add Automatically</label>
                </div>
                <div class="custom-control custom-radio mb-2">
                  <input type="radio" id="manualy" name="customRadio0" class="custom-control-input">
                  <label class="custom-control-label" for="manualy"> Add Manualy</label>
                </div>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
              </div>
              <hr>
              <div class="data-privacy">
                  <h4 class="mb-2"> Your Profile </h4>
                  <div class="custom-control custom-radio">
                    <input type="radio" id="public" name="customRadio1" class="custom-control-input" checked="">
                    <label class="custom-control-label" for="public"> Public </label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" id="friend" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="friend"> Friend </label>
                  </div>
                  <div class="custom-control custom-radio">
                    <input type="radio" id="spfriend" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="spfriend"> Specific Friend </label>
                  </div>
                  <div class="custom-control custom-radio mb-2">
                    <input type="radio" id="onlyme" name="customRadio1" class="custom-control-input">
                    <label class="custom-control-label" for="onlyme"> Only Me </label>
                  </div>
                  <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
              </div>
              <hr>
              <div class="data-privacy">
                <h4 class="mb-2"> Login Notification </h4>
                <div class="custom-control custom-radio">
                  <input type="radio" id="enable" name="customRadio2" class="custom-control-input">
                  <label class="custom-control-label" for="enable"> Enable </label>
                </div>
                <div class="custom-control custom-radio mb-2">
                  <input type="radio" id="disable" name="customRadio2" class="custom-control-input" checked="">
                  <label class="custom-control-label" for="disable"> Disable </label>
                </div>
                <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.</p>
              </div>
              <hr>
              <div class="data-privacy">
                <h4 class="mb-2">Privacy Help</h4>
                <a href="#"><i class="ri-customer-service-2-line mr-2"></i>Support</a>
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '../../config/pluginInit'

export default {
  name: 'PrivacySettings',
  mounted () {
    core.index()
  },
  data () {
    return {
      isPrivate: false,
      isActivityStatus: true,
      isAllowSharing: true
    }
  }
}
</script>
